import React from 'react'
import {
  Breadcrumbs,
  Hero,
  Page,
  ServiceContact,
  Testing,
} from '@components'

const meta = {
  title: 'Laboratorijsko ispitivanje | FSB d.o.o.',
  description: 'Osmišljavamo vlastite sustave pasivne protupožarne zaštite te iste ispituje u LTM – laboratoriju za toplinska mjerenja. ',
  image: require('@assets/images/lab.jpg'),
}

const Homepage = () => (
  <Page
    isHomepage
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/usluge', name: 'Usluge'},
        { link: '/usluge/laboratorijsko-ispitivanje', name: 'Laboratorijsko ispitivanje'},
      ]}
    />
    <Hero
      title="Usluga"
      subtitle="Laboratorijsko ispitivanje"
      description={meta.description}
    />
    <Testing />
    <ServiceContact id="ispitivanja" />
  </Page>
)

export default Homepage